<template>
  <div class="My-Results">
    <div class="stu-module-title pd20">我的成绩</div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础课成绩" name="one">
        <basic-result />
      </el-tab-pane>
      <el-tab-pane label="学位课成绩" name="two">
        <degree-result />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BasicResult from "./module/BasicResult";
import DegreeResult from "./module/DegreeResult";
export default {
  components: { BasicResult, DegreeResult },
  data() {
    return {
      activeName: 'one'
    };
  },
  methods: {
    handleClick(tab) {},
  },
};
</script>

<style scoped lang="less">
.My-Results {
  background: #fff;
  box-shadow: @shadow;
}
</style>