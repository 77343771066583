
<style scoped lang="less">
.BasicResult {
}
</style>
<template>
  <div class="BasicResult">
    <el-form :model="form" inline ref="basicResultForm" class="form-btn-group">
      <el-form-item label="课程名称" prop="courseName">
        <el-input
          v-model="form.courseName"
          placeholder="请输入"
          size="medium"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="button-item">
        <el-button @click="reset('basicResultForm')">重置</el-button>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :height="height"
      :default-sort="{ prop: 'semester', order: 'null' }"
      ref="tableRef"
      stripe
      v-loading="loading"
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column label="课程名称" prop="courseName"></el-table-column>
      <el-table-column label="课程编码" prop="courseCode"></el-table-column>
      <el-table-column label="学期" prop="semester"></el-table-column>
      <el-table-column label="是否发布" prop="publishOverrall"></el-table-column>
      <el-table-column label="离线学习成绩" prop="offlineScore">
        <template slot-scope="scope">
          <span v-if="scope.row.offlineScore==null||scope.row.offlineScore==''">暂无成绩</span>
          <span v-else>
            {{scope.row.offlineScore}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="在线交互成绩" prop="mutualScore">
        <template slot-scope="scope">
          <span v-if="scope.row.mutualScore==null||scope.row.mutualScore==''">暂无成绩</span>
          <span v-else>
            {{scope.row.mutualScore}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="在线作业成绩" prop="workScore">
        <template slot-scope="scope">
          <span v-if="scope.row.workScore==null||scope.row.workScore==''">暂无成绩</span>
          <span v-else>
            {{scope.row.workScore}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="免试成绩" prop="freeScore">
        <template slot-scope="scope">
          <span v-if="scope.row.freeScore==null||scope.row.freeScore==''">暂无成绩</span>
          <span v-else>
            {{scope.row.freeScore}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="期末考试成绩" prop="examScore">
        <template slot-scope="scope">
          <span v-if="scope.row.examScore==null||scope.row.examScore==''">暂无成绩</span>
          <span v-else>
            {{scope.row.examScore}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="总评成绩" prop="overallScore">
        <template slot-scope="scope">
          <span v-if="scope.row.overallScore==null||scope.row.overallScore==''">暂无成绩</span>
          <span v-else>
            {{scope.row.overallScore}}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="更新在线交互成绩" align="center" min-width="160">
        <template slot-scope="scope">
          <el-button type="text" @click="update(scope.row, scope.$index)"
            >更新</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { SelectStuScore, UpdateMutualScore } from "@/libs/api/student";
export default {
  name: "basic-result",
  data() {
    return {
      loading: false,
      height: window.innerHeight - 380,
      form: {
        courseName: "",
      },
      current: 1,
      pageSize: 10,
      total: 0,
      columns: [
        { prop: "courseName", label: "课程名称", tooltip: true, width: 160 },
        { prop: "courseCode", label: "课程编码" },
        { prop: "semester", label: "学期" },
        { prop: "publishOverrall", label: "是否发布" },
        { prop: "offlineScore", label: "离线学习成绩", width: 120 },
        { prop: "mutualScore", label: "在线交互成绩", width: 120 },
        { prop: "workScore", label: "在线作业成绩", width: 120 },
        { prop: "freeScore", label: "免试成绩" },
        { prop: "examScore", label: "期末考试成绩", width: 120 },
        { prop: "overallScore", label: "总评成绩" },
        // { prop: "isExe", label: "是否免试" },
      ],
      tableData: [],
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    init() {
      const params = {
        page: this.current,
        pageSize: this.pageSize,
        degreeFlag: 0,
        courseName: this.form.courseName,
      };
      this.loading = true;
      SelectStuScore(params)
        .then((res) => {
          this.loading = false;
          this.total = res.data.total;
          this.tableData = res.data.list;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    query() {
      this.current = 1;
      this.init();
    },
    update(row, index) {
      if (row) {
        UpdateMutualScore({ courseId: row.courseId }).then((res) => {
          this.init();
          this.$message.success("交互成绩更新成功");
        });
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>
