
<style scoped lang="less">
.DegreeResult {
}
</style>
<template>
  <div class="DegreeResult">
    <el-form :model="form" inline ref="degreeResultForm" class="form-btn-group">
      <el-form-item label="课程名称" prop="courseName">
        <el-input
          v-model="form.courseName"
          placeholder="请输入"
          size="medium"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="button-item">
        <el-button @click="reset('degreeResultForm')">重置</el-button>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :height="height"
      :default-sort="{ prop: 'semester', order: 'null' }"
      ref="tableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :show-overflow-tooltip="item.tooltip"
        :min-width="item.width"
      ></el-table-column>

      <el-table-column label="更新在线交互成绩" align="center" min-width="140">
        <template slot-scope="scope">
          <el-button type="text" @click="update(scope.row, scope.$index)"
            >更新</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { SelectStuScore, UpdateMutualScore } from "@/libs/api/student";
export default {
  name: "degree-result",
  data() {
    return {
      height: window.innerHeight - 380,
      form: {
        courseName: "",
      },
      current: 1,
      pageSize: 10,
      total: 0,
      columns: [
        { prop: "courseName", label: "课程名称", tooltip: true, width: 160 },
        { prop: "courseCode", label: "课程编码" },
        { prop: "semester", label: "学期" },
        { prop: "publishOverrall", label: "是否发布" },
        { prop: "offlineScore", label: "离线学习成绩", width: 120 },
        { prop: "mutualScore", label: "在线交互成绩", width: 120 },
        { prop: "workScore", label: "在线作业成绩", width: 120 },
        { prop: "freeScore", label: "免试成绩" },
        { prop: "examScore", label: "期末考试成绩", width: 120 },
        { prop: "overallScore", label: "总评成绩" },
        // { prop: "isExe", label: "是否免试" },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    init() {
      const params = {
        page: this.current,
        pageSize: this.pageSize,
        degreeFlag: 1,
        courseName: this.form.courseName,
      };
      SelectStuScore(params).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    query() {
      this.current = 1;
      this.init();
    },
    update(row, index) {
      if (row) {
        UpdateMutualScore({ courseId: row.courseId }).then((res) => {
          this.init();
          this.$message.success("交互成绩更新成功");
        });
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>
